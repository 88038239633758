import { useEffect, useState } from "react";
import Header from "../components/global/Header";
import RollingNumber from "../components/global/RollingNumber";
import "../styles/community.css"
import { Modal } from "rsuite";
import { emailRegex, nameRegex } from "../utils/RegExpressions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { REACT_APP_DEV_URL } from "../utils/api";
import axios from "axios";
import toast from "react-hot-toast";
import { Input } from 'antd';
import Select from "react-select";

const Community = () => {

    const [communityCount, setCommunityCount] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const initialValues = {
        name: "",
        email: "",
        mobile: "",
        experience: "",
        creatorType: ""
    };

    const creatorTypeOptions = [
        {
            label: "Director",
            value: "Director"
        },
        {
            label: "Producer",
            value: "Producer"
        },
        {
            label: "Actor",
            value: "Actor"
        },
        {
            label: "⁠Script writer",
            value: "⁠Script writer"
        }
    ]

    const validationSchema = Yup.object({
        name: Yup.string()
        .required("Please enter your name")
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name cannot exceed 30 characters")
        .matches(nameRegex, "Invalid name"),
        email: Yup.string()
        .required("Email is required")
        .matches(emailRegex, "Invalid Eamil"),
        mobile: Yup.string()
        .required("Phone number is required")
        .min(7, "Phone Number must be at least 7 digits")
        .max(13, "Phone Number cannot exceed 13 digits")
        .matches(/^\d+$/, "Phone number must only contain numbers"),
        experience: Yup.string()
        .required("Please fill the experience"),
        creatorType: Yup.string()
        .required("Please select a creator type")
    });

    const getCount = async () => {
        const res = await axios.get(
        `${REACT_APP_DEV_URL}/community/get-count`,
        );
    
        if (res?.status !== 200) {
            toast.error(res?.data?.message);
        } else {
            if (res?.data?.data?.count) {
                setCommunityCount(res.data.data.count + 475);
            }
        }
    }

    const handleSubmit = async(values: typeof initialValues) => {
        axios.post(`${REACT_APP_DEV_URL}/community/join`,values).then((res) => {
            if (res?.status === 200) {
                getCount();
                setOpen(false);
                toast.success('You have joined the community successfully');
                setTimeout(() => { window.open('https://wa.me/917200711366', '_blank') }, 800);
            } else {
                toast.error(res?.data?.message);
            }
        }).catch((error) => {
            toast.error(error.response?.data?.message || error.message);
        })
    };

    useEffect(() => {
        getCount();
    }, []);

    const JoiningForm = () => {
        return (
            <div className="formContainer">
            <h3 className="joinOurCommunity">Join our community</h3>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, touched, errors, isSubmitting }) => (
                <Form className="form">
                    {/* Name Field */}
                    <div className="fieldGroup">
                    <label htmlFor="name">Name</label>
                    <Field
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        className={`${'input'} ${
                        touched.name && errors.name ? 'errorInput' : ""
                        }`}
                    />
                    <ErrorMessage
                        name="name"
                        component="div"
                        className="errorText"
                    />
                    </div>

                    {/* Email Field */}
                    <div className="fieldGroup">
                    <label htmlFor="email">Email</label>
                    <Field
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        className={`${"input"} ${
                        touched.email && errors.email ? "errorInput" : ""
                        }`}
                    />
                    <ErrorMessage
                        name="email"
                        component="div"
                        className="errorText"
                    />
                    </div>

                    {/* Phone Number Field */}
                    <div className="fieldGroup">
                    <label htmlFor="mobile">Phone Number</label>
                    <Field
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your phone number"
                        className={`${"input"} ${
                        touched.mobile && errors.mobile
                            ? "errorInput"
                            : ""
                        }`}
                    />
                    <ErrorMessage
                        name="mobile"
                        component="div"
                        className="errorText"
                    />
                    </div>
                    <div className="fieldGroup">
                        <label htmlFor="experience">Experience</label>
                        <Field
                            as="textarea"
                            className={`experienceTextArea input ${
                                touched.experience && errors.experience ? "errorInput" : ""
                            }`}
                            id="experience"
                            name="experience"
                            placeholder="Please provide the experience"
                            rows={4}
                        />
                        <ErrorMessage name="experience" component="div" className="errorText" />
                    </div>
                    <div className="fieldGroup">
                        <label htmlFor="creatorType">Creator Type</label>
                        <Select
                            className={`creatorTypeDropdown input ${
                                touched.creatorType && errors.creatorType ? "errorInput" : ""
                            }`}
                            options={creatorTypeOptions}
                            id="creatorType"
                            placeholder="Please select a creator type"
                            onChange={(option) => setFieldValue("creatorType", option?.value)}
                        />
                        <ErrorMessage name="creatorType" component="div" className="errorText" />
                    </div>

                    {/* Submit Button */}
                    <button
                    type="submit"
                    className="submitButton"
                    disabled={isSubmitting}
                    >
                    Submit
                    </button>
                </Form>
                )}
            </Formik>
            </div>
        )
    }

    return (
        <div className="communityContainer">
            <Header />
            <h3 className="joinOurCommunity">Unlock Your Creative Potential!</h3>
            <div className="container">
                <div>
                    <h5 className="joinContent">Join the Global Creators Community—the ultimate hub for filmmakers, scriptwriters, and content creators to connect, learn, and grow. Dive into free expert-led webinars, industry collaborations, and endless opportunities to elevate your craft. Your journey to creative success starts here—don’t miss out!</h5>
                </div>
                <div>
                    <div>
                        <RollingNumber number={communityCount} />
                    </div>
                    <div className="joinNowContainer">
                        <button onClick={handleOpen} className="joinNowButton">Join Now</button>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                className="downloadModal"
                size="sm"
                backdrop="static"
            >
                <Modal.Header />
                <Modal.Body>
                    <JoiningForm />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Community;