import React, { useEffect, useState } from "react";

import "../../styles/rollingNumber.css";

const RollingNumber = ({ number }: { number: number }) => {
  // Convert the number to an array of digits (ensure 4 digits minimum)
  const digits = number.toString().padStart(4, "0").split("");

  // State to store the current displayed digits and detect changes
  const [currentDigits, setCurrentDigits] = useState(digits);

  // State to store which digits are being updated
  const [updatedDigits, setUpdatedDigits] = useState(new Array(4).fill(false));

  useEffect(() => {
    const newDigits = number.toString().padStart(4, "0").split("");
    const updated = newDigits.map((digit, index) => digit !== currentDigits[index]);

    // Update state with new digits and changes
    setCurrentDigits(newDigits);
    setUpdatedDigits(updated);

    // Reset the updatedDigits state after the animation duration (0.5s here)
    const timeout = setTimeout(() => {
      setUpdatedDigits(new Array(4).fill(false));
    }, 500);

    return () => clearTimeout(timeout);
  }, [number]);

  return (
    <div className="rolling-number-container">
      {currentDigits.map((digit: any, index: number) => (
        <div className="digit-container" key={index}>
          <div
            className={`rolling-digit ${updatedDigits[index] ? "rolling" : ""}`}
            style={{
              transform: `translateY(-${parseInt(digit, 10) * 4}rem)`,
            }}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <div key={i} className="digit">
                {i}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RollingNumber;