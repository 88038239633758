import { useRef, useState } from "react";
import { Input } from 'antd';
import ReactQuill from "react-quill";
import { REACT_APP_DEV_URL } from "../utils/api";
import axios from "axios";
import "react-quill/dist/quill.snow.css"; // Import the Quill theme
import "../styles/adminBlog.css";
import toast from "react-hot-toast";

const { TextArea } = Input;

const AdminBlog = () => {

    const token = sessionStorage.getItem("token") || "";
    const [blog, setBlog] = useState({
        title: '',
        poster: '',
        date: '',
        content: ''
    })
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const imageInputRef = useRef<HTMLInputElement>(null);

    const modules = {
        toolbar: [
          ["bold", "italic", "underline"], // Text formatting options
          [{ list: "ordered" }, { list: "bullet" }], // List options
          ["link"], // Insert links
          [{ align: [] }], // Align text
          ["clean"], // Remove formatting
        ],
    };
    
    const formats = [
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",
        "link",
        "align",
    ];

    const handleFileChange = (event: any) => {
        const files = event.target.files; // Get selected files
        if (files) {
          const fileArray = Array.from(files);
    
          if (fileArray.length > 1) {
            toast.error("You can only upload 1 file");
            return;
          }
    
          setSelectedImage(files[0]);
        }
    };

    const handleUploadImage = async () => {
        try {
          if (!selectedImage) {
            toast.error("No file selected.");
            return;
          }
          const formData = new FormData();
          // Append each image to the FormData
          formData.append("blog", selectedImage)
          const res = await axios.post(
            `${REACT_APP_DEV_URL}/blog/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.data.status !== 200) {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            if (res?.data?.data?.url) {
                setBlog({ ...blog, poster: res.data.data.url });
            }
            setSelectedImage(null);
            if (imageInputRef.current) {
              imageInputRef.current.value = "";
            }
          }
        } catch (e) {
          toast.error("error uploading file");
        }
    };

    const submit = async () => {
       const payload = JSON.parse(JSON.stringify(blog));
       payload['date'] = new Date().toISOString();
       const { title, poster, content } = payload;
       if (title === '') {
        toast.error("Please provide a title to your blog");
        return;
       } else if (content === '' || content === '<p><br></p>') {
        toast.error("Please provide a content to your blog");
        return;
       } else if (poster === '') {
        toast.error("Please upload an image for your blog");
        return;
       }
       try {
        const res = await axios.post(
            `${REACT_APP_DEV_URL}/blog/create`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.data.status !== 200) {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            setBlog({
                title: '',
                poster: '',
                date: '',
                content: ''
            });
          }
       } catch (e) {
        toast.error("error creating a blog");
      }
    }

    return (
        <div className="container adminBlogContainer">
            <div className="titleSubmitContainer">
                <label htmlFor={blog?.title}>Title</label><button onClick={submit} className="submitBtn">
                    Submit your blog
                </button>
            </div>
            <TextArea className="titleField" onChange={(event) => setBlog({...blog, title: event.target.value})} placeholder="Give a title for your blog" autoSize />
            <label htmlFor={blog?.poster}>Upload your blog image</label>
            <div
                style={{
                  backgroundColor: "#2F144B",
                  height: "46px",
                  marginBottom: "1.5rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  display: "flex",
                  padding: "0px 2px",
                  position: "relative",
                  width: "45%"
                }}
              >
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  ref={imageInputRef}
                  className=""
                  style={{ color: "white", padding: "10px" }}
                  onChange={handleFileChange}
                />

                <button
                  className="uploadBtn"
                  onClick={handleUploadImage}
                >
                  {"Upload"}
                </button>
            </div>
            <label htmlFor={blog?.content}>Content</label>
            <ReactQuill
                className="quillEditor"
                theme="snow"
                value={blog.content}
                onChange={(event) => setBlog({...blog, content: event})}
                modules={modules}
                formats={formats}
                placeholder="Write something amazing here..."
            />
            {/* <h4>Generated HTML Output:</h4>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                </div> */}
            
        </div>
    )
}

export default AdminBlog;