import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_DEV_URL } from "../../utils/api";
import toast from "react-hot-toast";

const MobileNumber = () => {
  const [phoneno, setPhoneNo] = useState("");
  const navigate = useNavigate();
  const oauthName = sessionStorage.getItem("oauthname");
  const oauthEmail = sessionStorage.getItem("oauthemail");

  const handleLogin = async () => {
    try {
      sessionStorage.setItem("mobile", phoneno);
      const res = await axios.post(
        `${REACT_APP_DEV_URL}/user/request-reset-password`,
        {
          username: phoneno,
          scenario: "OAUTH",
          name: oauthName,
          email: oauthEmail,
        }
      );
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      } else {
        sessionStorage.setItem("mobileloginpath", "/mobile-signin");
        navigate("/otp");
        sessionStorage.setItem("otp", res.data.data.otp);
        // console.log("res", res.data.data);
      }
    } catch {
      toast.error("something went wrong");
    }
  };
  return (
    <div>
      <div>
        <p className="signInText fontPoppins">SIGN IN</p>
        <p className="loginSubtext fontPoppins">Login to access your account</p>
      </div>
      <form className="loginForm">
        <aside>
          <p className="loginLabel fontPoppins">Mobile</p>
          <input
            type="number"
            placeholder="Please enter mobile"
            className="fontPoppins"
            id="phonenumber"
            value={phoneno}
            onChange={(e) => {
              setPhoneNo(e.target.value);
            }}
          />
        </aside>

        <button className="" type="button" onClick={handleLogin}>
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default MobileNumber;
