import ToggleList from "../components/global/toggleList";

import buyLanding from "../assets/buyLanding.png"
import bgPurple1 from "../assets/bgPurple1.png";
import bgPurple2 from "../assets/bgPurple2.png";
import buyMidSection1 from "../assets/buyMidSection1.png";
import buyMidSection2 from "../assets/buyMidSection2.png";

import location from "../assets/location.png";
import catalog from "../assets/catalog.png";
import customize from "../assets/customize.png";
import paste from "../assets/paste.png";
import connect from "../assets/connect.png";
import helpline from "../assets/helpline.png";
import onboard from "../assets/onboard.png";
import moreReasonsImage from "../assets/moreReasons.png";
import checkCircle from "../assets/checkCircle.png";
import startBuying from "../assets/startBuyingBG.png";
import whatsappImage from "../assets/whatsappImage.png";

import "../styles/staticBuy.css";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import { useNavigate } from "react-router-dom";

export function StaticBuy() {
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `linear-gradient(90deg, #2F005D 40.5%, rgba(0, 0, 0, 0.5) 100%), url(${buyLanding})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "30vh",
    width: "100vw",
  };

  const backGroundStyleSection1 = {
    backgroundImage: `url(${bgPurple1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const backGroundStyleSection2 = {
    backgroundImage: `url(${bgPurple2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const startBuyingBackGroundStyle = {
    backgroundImage: `url(${startBuying})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const midSections = [
    {
      tagLine: "The Fastest Way \n To Acquire Content Rights",
      content:
        "Acquiring content rights involves identifying the rights holder and engaging a seasoned entertainment lawyer or rights acquisition specialist to process the intended use, required rights, and financial offer. Without knowledge on how to navigate the process, it can often cause unnecessary delays and complications.",
      backgroundImage: backGroundStyleSection2,
      image: buyMidSection1,
      imageLeft: true,
          },
    {
      tagLine: "",
      content:
        "Producerbazaar simplifies finding the perfect content for your needs. Our trusted platform streamlines access to a vast library of content rights, saving you time and ensuring everything is above board.",
      backgroundImage: backGroundStyleSection2,
      image: buyMidSection2,
      imageLeft: false,
          },
  ];

  const reasonsToChooseProducerBazaar = [
    {
      image: location,
      content: "Safe and Verified market place",
    },
    {
      image: catalog,
      content: "Extensive Catalog",
    },
    {
      image: customize,
      content: "Customize rights & duration with few clicks",
    },
    {
      image: paste,
      content: "We do all the heavy lifting behalf of you",
    },
    {
      image: connect,
      content: "Connect with rights holders swiftly",
    },
    {
      image: helpline,
      content: "Conduct financial transactions with confidence",
    },
    {
      image: onboard,
      content: "Onboard in 3 simple steps",
    },
  ];

  const moreReasons = [
    {
      content: "Easy to search with 20+ filters",
    },
    {
      content: "5000+ movie database",
    },
    {
      content: "Exclusive dashboards for buyers and sellers",
    },
    {
      content: "Track everything in one place",
    },
  ];

  const buyingContentRights = [
    { text: "Is it free to sign up as a Producer Bazaar buyer?", subsection: "Yes, signing up for a Producer Bazaar buyer account is currently free. This allows you to explore the platform, discover a wide variety of movie IP rights, and curate content that aligns with your interests." },
    { text: "What information do I need to sign up?", subsection: "As Producer Bazaar caters to businesses, you\'ll need to have the Business/Organization you represent ready when filling out the simple signup form." },
    { text: "What are the benefits of having a Producer Bazaar buyer account?", subsection: "<p style='margin-bottom: 10px !important;'>· &nbsp; <strong>Saved Searches & Favorites: Organize your search history and shortlist interesting IP rights for easier reference. This allows you to quickly revisit promising opportunities.</strong></p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp; <strong>Streamlined Communication: </strong>Communicate directly with sellers through a secure messaging system.Negotiate deals and ask questions with ease.</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp; <strong>Purchase Tracking: </strong>Monitor the progress of your IP right purchases. Stay informed on the status of your transactions.</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp; <strong>Exclusive Offers (optional): </strong>Get notified about exclusive deals and upcoming IP rights releases. Be among the first to know about exciting new content.</p>\
    " },
    { text: "How do I find the IP rights I\'m looking for? ", subsection: "Producer Bazaar offers a user-friendly search function with multiple filters at your disposal. You can refine your search by genre, budget, stage of development (e.g., script, pre-production, completed film), and other criteria to find content that matches your specific needs. Additionally, you can browse curated collections created by the Producer Bazaar team to discover high-potential IP rights." },
    { text: "What information can I expect to see for each IP right?", subsection: "Each IP right listing typically includes a detailed description that gives you a clear understanding of the content. You\'ll also find contact information for the rights holder, allowing you to initiate communication directly. Exclusivity details will be specified, outlining the rights conveyed with the purchase. Additionally, some listings may even include sample materials like scripts, trailers, or other promotional materials to give you a deeper look at the IP right." },
    { text: "How can I contact the seller of an IP right?", subsection: "Producer Bazaar provides a secure messaging system for you to communicate directly with the seller. This allows you to ask any questions you may have about the IP right, initiate negotiations, and discuss the details of a potential purchase." },
    { text: "How do I purchase an IP right?", subsection: "Producer Bazaar offers a user-friendly search function with multiple filters at your disposal. You can refine your search by genre, budget, stage of development (e.g., script, pre-production, completed film), and other criteria to find content that matches your specific needs. Additionally, you can browse curated collections created by the Producer Bazaar team to discover high-potential IP rights." },
    { text: "What happens after I purchase an IP right? ", subsection: "Once you have purchased an IP right, you will be granted the rights outlined in the agreement with the seller. Producer Bazaar will facilitate the transfer of ownership and provide any necessary documentation to ensure a clear and legal transaction." },
    { text: "Producer Bazaar\'s Role in Negotiation", subsection: "Producer Bazaar acts as a facilitator to streamline and secure negotiations between buyers and sellers of movie IP rights. Producer Bazaar facilitates secure negotiations by providing a safe messaging system for communication and deal-making. To ensure trust and transparency, they may verify sellers (where applicable) and offer dispute resolution services if disagreements arise during the negotiation process. However, it\'s important to remember that Producer Bazaar doesn\'t directly participate in negotiations, and sellers ultimately set the terms." },
  ];

  const Landing = () => {
    const tagLine = "Acquire content rights \n without any hassle";
    return (
      <div className="staticBuyCard">
        <div style={backgroundStyle}>
          <div className="tagLine">
            {tagLine.split("\n").map((line, index) => (
              <div key={index}>
                {line}
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const MidSection = () => {
    return (
      <div>
        {midSections.map((section) => {
          return (
            <div style={section.backgroundImage}>
              <div>
                {section.imageLeft && (
                  <div className="midSectionContainer">
                    <div>
                      <img className="midSectionImage" src={section.image} />
                    </div>
                    <div className="midSectionContentContainer">
                      <div className="midSectionTagLine">
                        {section.tagLine.split("\n").map((line, index) => (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        ))}
                      </div>
                      <div className="midSectionContent">{section.content}</div>
                    </div>
                  </div>
                )}
                {!section.imageLeft && (
                  <div className="midSectionContainer">
                    <div className="midSectionContentContainer">
                      <div className="midSectionTagLine">
                        {section?.tagLine}
                      </div>
                      <div className="midSectionContent midSectionContentText">{section.content}</div>
                    </div>
                    <div>
                      <img className="midSectionImage" src={section.image} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const ChooseProducerBazaar = () => {
    return (
      <div className="reasonsContainer">
        <div className="heading">Why Choose Producer Bazaar</div>
        <div className="reasonsToChooseContainer">
          {reasonsToChooseProducerBazaar.map((reason) => {
            return (
              <div className="reasonContainer">
                <img className="reasonImage" src={reason.image} />
                <div>{reason.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const MoreReasons = () => {
    return (
      <div className="moreReasonsContainer">
        <div className="heading">More Reasons To Choose Producer Bazaar</div>
        <div className="moreReasonsSection">
          <div className="moreReasonsImageContainer">
            <img className="moreReasonsImage" src={moreReasonsImage} />
          </div>
          <div className="moreReasonsContentContainer">
            <div className="moreReasonsContent">
              {moreReasons.map((reason) => {
                return (
                  <div className="moreReasons">
                    <div>
                      <img
                        className="moreResonsCheckCircle"
                        src={checkCircle}
                      />
                    </div>
                    <div className="reasonText">{reason.content}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BuyingContentRights = () => {
    return (
      <div style={startBuyingBackGroundStyle}>
        <div className="buyingContentRightsContainer">
          <div className="heading">
            Buying Content Rights With Producer Bazaar
          </div>
          <div>
            <ToggleList sentences={buyingContentRights} />
          </div>
        </div>
      </div>
    )
  }

  const StartBuying = () => {
    return (
      <div style={backGroundStyleSection2}>
        <div>
          <div className="heading">
            <p style={{ marginBottom: '-30px' }}>Start Buying Movie & Digital Rights</p>
            <p>At the right price </p>
          </div>
          <div className="startBuyingContainer">
            <a href="https://wa.me/917200711366" target="_blank"><div className="whatsappChatContainer">
              <img className="whatsappChatImage" src={whatsappImage} />
              <div className="textStyle">Chat with sales team</div>
            </div></a>
            <div onClick={() => navigate("/sign-up")} className="signUpForFreeContainer">
              Signup
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Header></Header>
        <Landing></Landing>
        <MidSection></MidSection>
        <ChooseProducerBazaar></ChooseProducerBazaar>
        <MoreReasons />
        <BuyingContentRights />
        <StartBuying />
        <Footer></Footer>
      </div>
    </div>
  );
}
