import React from "react";
import SignInLayout from "../components/global/SignInLayout";
import MobileNumber from "../components/signin/MobileNumber";
import "./../styles/signin.css";

const MobileLogin = () => {
  return (
    <>
      <SignInLayout children={<MobileNumber />} />
    </>
  );
};

export default MobileLogin;
