import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { mobileRegex, passwordRegex } from "../../utils/RegExpressions";
import toast from "react-hot-toast";
import { REACT_APP_DEV_URL } from "../../utils/api";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import fbImg from "../../assets/facebook.png";
import googleImg from "../../assets/google.png";
// import { FacebookLogin } from "react-facebook-login-lite";

const Login = () => {
  const [signInValues, setSignInValues] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const validateInput = () => {
    let errors = {
      username: "",
      password: "",
    };

    // Validate username (mobile number)
    if (signInValues.username === "") {
      errors.username = "Please enter mobile number";
    }

    if (
      signInValues.username !== "" &&
      !mobileRegex.test(signInValues.username)
    ) {
      errors.username = "Please enter valid mobile number";
    }

    // Validate password
    if (signInValues.password === "") {
      errors.password = "Please enter a password";
    }
    if (
      signInValues.password !== "" &&
      !passwordRegex.test(signInValues.password)
    ) {
      errors.password = "Invalid password";
    }

    // Set the error state
    setError(errors);

    // Return true if there are no errors
    return !errors.username && !errors.password;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setSignInValues((prev: any) => ({
      ...prev,
      [id]: value,
    }));

    // Reset errors on input change
    setError((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  const handleLogin = async () => {
    if (validateInput()) {
      try {
        const loginValues = {
          username: signInValues.username,
          password: signInValues.password,
        };
        const res = await axios.post(
          `${REACT_APP_DEV_URL}/user/login`,
          loginValues
        );

        if (res.data.status !== 200) {
          toast.error(res.data.message);
        } else {
          if (res.data.data.name && res.data.data.token) {
            sessionStorage.setItem("mobileno", signInValues.username);
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("userName", res.data.data.name);
            sessionStorage.setItem("role", res.data.data.role);
            sessionStorage.setItem(
              "profilePic",
              res.data.data.dp !== null ? res.data.data.dp : ""
            );
            toast.success("Loggedin successfully");
            navigate("/");
          } else {
            toast.error(res.data.data.message);
          }
        }
      } catch (error) {
        toast.error("failed during login");
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleLogin(); // Call signIn when Enter is pressed
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      console.log("res", response);
      const token = response.access_token;

      getGoogleUserInfo(token);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const getGoogleUserInfo = async (accToken: any) => {
    try {
      const res = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accToken}`,
          },
        }
      );
      const { name, email } = res.data;
      isUserExist(email);
      sessionStorage.setItem("userName", name);
      sessionStorage.setItem("oauthname", name);
      sessionStorage.setItem("oauthemail", email);
    } catch (error) {
      console.log("Google authentication failed", error);
      toast.error("Google authentication failed try again");
    }
  };

  // const handleFacebookLogin = (response: any) => {
  //   console.log("Facebook Response:", response);

  //   if (response) {
  //     const username = response.name;
  //     const email = response.email;
  //     const userId = response.id;

  //     console.log("Name:", username);
  //     console.log("Email:", email);
  //     console.log("User ID:", userId);
  //   } else {
  //     console.error("User cancelled login or did not fully authorize.");
  //   }
  // };

  const isUserExist = async (email: string) => {
    try {
      const res = await axios.post(
        `${REACT_APP_DEV_URL}/user/oauth/user-exist`,
        {
          email,
        }
      );
      const { userFlag, token, userInfo } = res.data.data;
      if (userFlag) {
        sessionStorage.setItem("mobileno", userInfo.username);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("userName", userInfo.name);
        sessionStorage.setItem("role", userInfo.role);
        sessionStorage.setItem(
          "profilePic",
          userInfo.dp !== null ? userInfo.dp : ""
        );
        navigate("/");
      } else {
        navigate("/mobile-signin");
      }
    } catch (error) {
      console.log("Authentication failed", error);
      toast.error("Authentication failed try again");
    }
  };

  return (
    <div>
      <div>
        <p className="signInText fontPoppins">SIGN IN</p>
        <p className="loginSubtext fontPoppins">Login to access your account</p>
      </div>
      <form className="loginForm">
        <aside>
          <p className="loginLabel fontPoppins">Mobile</p>
          <input
            type="number"
            placeholder="Please enter mobile"
            className="fontPoppins"
            id="username"
            value={signInValues.username}
            onChange={handleInputChange}
          />
          {error.username && <p className="error">{error.username}</p>}
        </aside>
        <aside>
          <p className="loginLabel fontPoppins">Password</p>
          <input
            type="password"
            placeholder="Password"
            className="fontPoppins"
            id="password"
            value={signInValues.password}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {error.password && <p className="error">{error.password}</p>}
        </aside>

        <Link to="/forgot-password">
          <p className="forgotPass fontPoppins">Forgot Password</p>
        </Link>

        <button className="" type="button" onClick={handleLogin}>
          LOGIN
        </button>

        <aside style={{ width: "100%", display: "flex", gap: "6px" }}>
          {/* 1029102428344-4gha0mjvq622e88iso7ouhj76aej9m84.apps.googleusercontent.com */}
{/*           <GoogleOAuthProvider clientId="942348132181-vtuf4j655mbc6s2ki73fv9ajcp7nodej.apps.googleusercontent.com"> */}
          <GoogleOAuthProvider clientId="1029102428344-4gha0mjvq622e88iso7ouhj76aej9m84.apps.googleusercontent.com">
            <div
              className="loginSocial"
              onClick={() => {
                googleLogin();
              }}
            >
              <img src={googleImg} alt="icon" />
            </div>
          </GoogleOAuthProvider>{" "}
          {/* <FacebookLogin
            appId="516131957766745"
            onSuccess={handleFacebookLogin}
            onFailure={() => console.error("Login failed")}
            render={(renderProps: any) => (
              <button className="loginSocial" onClick={renderProps.onClick}>
                <img src={fbImg} alt="icon" />
              </button>
            )}
          /> */}
          <div className="loginSocial">
            <img src={fbImg} alt="icon" />
          </div>
        </aside>

        <p className="fontPoppins accSignUp ft16 ftw400">
          Don’t have an account?
          <Link to="/sign-up" style={{ color: "#ffffff", marginLeft: "10px" }}>
            <span>Sign Up</span>
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
