import axios from "axios";
import { REACT_APP_DEV_URL } from "../utils/api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import "../styles/adminCommunity.css";

interface DataType {
    _id: string;
    name: string;
    email: string;
    // experience: string;
    // creatorType: string;
    createdAt: string;
    updatedAt: string;
}

const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    // {
    //   title: 'Creator Type',
    //   dataIndex: 'creatorType',
    //   key: 'creatorType',
    // },
    // {
    //   title: 'Experience',
    //   dataIndex: 'experience',
    //   key: 'experience',
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: false }).replace(/,/g, "")
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => new Date(date).toLocaleString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: false }).replace(/,/g, "")
    }
  ];

const AdminCommunity = () => {

    const [communityUsers, setCommunityUsers] = useState([]);
    const token = sessionStorage.getItem("token") || "";

    const getCommunityUsers = async () => {
        const res = await axios.get(
            `${REACT_APP_DEV_URL}/community/get-all`,
            {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
        );
    
        if (res?.status !== 200) {
            toast.error(res?.data?.message);
        } else {
            if (res?.data?.data) {
                setCommunityUsers(res.data.data);
            }
        }
    }

    useEffect(() => {
        getCommunityUsers();
    }, []);

    return (
        <div className="container adminCommunity">
            <Table<DataType> rowClassName={() => "custom-row"} columns={columns} dataSource={communityUsers} />;
        </div>
    )
}

export default AdminCommunity;